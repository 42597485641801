// indexedDB.js

const DB_NAME = 'my-database'
const DB_VERSION = 1

const openDB = () => new Promise((resolve, reject) => {
  const request = indexedDB.open(DB_NAME, DB_VERSION)

  request.onupgradeneeded = event => {
    const db = event.target.result

    // Create an object store if it doesn't exist
    if (!db.objectStoreNames.contains('data')) {
      db.createObjectStore('data', { keyPath: 'id', autoIncrement: true })
    }
  }

  request.onsuccess = event => {
    resolve(event.target.result)
  }

  request.onerror = event => {
    reject(event.target.error)
  }
})

// eslint-disable-next-line import/prefer-default-export
export { openDB }
